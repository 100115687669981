<template>
  <CRow>
    <CCol col="12" xl="8">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            Users
          </CCardHeader>
          <CCardBody>
            <CAlert :show.sync="dismissCountDown" color="primary" fade> ({{ dismissCountDown }}) {{ message }} </CAlert>
            <CDataTable hover striped :items="items" :fields="fields" :items-per-page="5" pagination>
              <template #status="{item}">
                <td>
                  <CBadge :color="statuses[item._status].color">{{ statuses[item._status].name }}</CBadge>
                </td>
              </template>
              <template #registered="{item}">
                <td>
                  {{ (item._created_at || '').substr(0, 10) }}
                </td>
              </template>
              <template #show="{item}">
                <td>
                  <CButton color="secondary" @click="showUser(item.id)">Show</CButton>
                </td>
              </template>
              <template #edit="{item}">
                <td>
                  <CButton v-if="item.id !== 1" color="primary" @click="editUser(item.id)">Edit</CButton>
                </td>
              </template>
              <template #delete="{item}">
                <td>
                  <CButton v-if="item.id !== 1 && loggedUser.id !== item.id" color="danger" @click="deleteUser(item.id)">Delete</CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import viewMixin from '@/app/views/_mixins/view-mixin'

export default {
  name: 'Users',
  mixins: [viewMixin],
  data: () => {
    return {
      items: [],
      fields: ['id', 'name', 'registered', '_roles', 'status', 'show', 'edit', 'delete'],
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      you: null,
      message: '',
      showMessage: false,
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false,

      statuses: {
        0: { name: 'Inactive', color: 'success' },
        1: { name: 'Active', color: 'secondary' },
        2: { name: 'Pending', color: 'warning' },
        3: { name: 'Banned', color: 'danger' }
      }
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  methods: {
    userLink(id) {
      return `users/${id.toString()}`
    },
    editLink(id) {
      return `users/${id.toString()}/edit`
    },
    showUser(id) {
      const userLink = this.userLink(id)
      this.$router.push({ path: userLink })
    },
    editUser(id) {
      const editLink = this.editLink(id)
      this.$router.push({ path: editLink })
    },
    deleteUser(id) {
      console.log(id)
      this.$http
        .delete('admin/users', { id })
        .then(() => {
          this.message = 'Successfully deleted user.'
          this.showAlert()
          this.getUsers()
        })
        .catch(error => {
          console.error(error)
          this.$router.push({ path: '/login' })
        })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    getUsers() {
      this.$http
        .get('admin/users')
        .then(response => {
          this.items = response.data.users
          this.you = response.data.you
        })
        .catch(error => {
          console.error(error)
          this.$router.push({ path: '/login' })
        })
    }
  },
  mounted() {
    this.getUsers()
  }
}
</script>
